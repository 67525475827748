import React from 'react';
import './App.css';
import logo from './logo.png';

function App() {
  return (
    <article className="main-container">
      <div className="box-container">
        <div className="white-box">
          <img src={logo} alt="" />
          <h1>Our new website <br /> is on the way.</h1>
          <p>Tangram Global specialises in helping individuals and organisations grow and prosper in a changing world.</p>
          <p>For more information on executive and team coaching, leadership development, and organisational change, please contact:  <a href="mailto:info@tangramglobal.com">info@tangramglobal.com</a></p>
        </div>
      </div>
    </article>
    
  );
}

export default App;
